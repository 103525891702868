body {
  background-image: url(./img/original.gif);
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  color: white;
  /* cursor: url(../public/img/b.ico), pointer; */
  /* cursor: pointer; */
}

h2, h3, h4 {
  cursor: pointer;
}

h1 {
  font-size: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

h2 {
  font-size: 2rem;
}

.judo {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: -4rem;
  margin-bottom: 1rem;
}

#dev, #prod, #greek {
  height: 20vh;
}

#dev:hover, #prod:hover, #greek:hover {
  text-decoration: line-through;
  background-image: url('https://thumbs.gfycat.com/ImpassionedTameBullfrog-max-1mb.gif');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  body {
    background-attachment: scroll;
  }
}

